import { usePageRoute } from '../context/page-data.context';
import { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { RouteStore } from './route-store';
import { routes } from './routes';
import { PageLoading } from './PageLoading';
import { RouteSnapshot } from '../lib/router/route-snapshot';
import { isSSR } from '../lib/util/is-ssr';
import { LoadingService, LoadingState, useLoadingService } from '../lib/util/loading-service';
import { isLoadable } from './util';
import { usePageSpinner } from '../components/PageSpinner';
import { isBool } from '@juulsgaard/ts-tools';

const BlockLoadingContext = createContext<LoadingService>({} as LoadingService);

const store = new RouteStore(routes);

function handleScroll(route: RouteSnapshot) {
  if (isSSR()) return;

  if (route.fragment) {
    const element = document.getElementById(route.fragment);

    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
      return;
    }
  }

  window.scrollTo({ top: route.scrollOffset || 0, behavior: 'smooth' });
}

export function PageRenderer() {
  const pageRoute = usePageRoute();
  const pageType = pageRoute.page.pageType;
  const [loadedPageType, setLoadedPageType] = useState<string|undefined>();

  const loading = useRef<LoadingState | undefined>();
  const loadingService = usePageSpinner();

  const Page = useMemo(
    () => store.getComponent(pageType),
    [pageType]
  );

  useEffect(() => {
    const result = store.getLoadingState(pageType);

    if (isBool(result)) {
      loading?.current?.cancel();
      return;
    }

    if (!loading.current?.loading) {
      loading.current = loadingService.startLoad();
    }

    let disposed = false;
    result.finally(() => {
      if (disposed) return;
      loading?.current?.cancel();
      setLoadedPageType(pageType)
    });
    return () => {disposed = false};
  }, [pageType]);

  useEffect(() => () => loading.current?.cancel(), []);

  const [blocksLoading, blockLoadingService] = useLoadingService();

  useEffect(() => {
    if (!Page) return;

    const loaded = !isLoadable(Page) || loadedPageType === pageRoute.page.pageType;
    if (!loaded || blocksLoading) return;

    // Cancel if blocks have started to load during render
    if (blockLoadingService.loading) return;

    handleScroll(pageRoute.route);
  }, [pageRoute, Page, loadedPageType, blocksLoading]);

  // TODO: Handle page not existing
  if (!Page) return <></>;

  const page = isLoadable(Page) ? <Page fallback={<PageLoading />}/> : <Page/>;

  return (
    <BlockLoadingContext.Provider value={blockLoadingService}>
      {page}
    </BlockLoadingContext.Provider>
  );
}

export function useBlockLoading() {
  return useContext(BlockLoadingContext);
}