import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

export interface UiState {
  hideHeader: boolean;
  hideFooter: boolean;
}

class UiService {
  constructor(private modifyState: (modify: (state: UiState) => UiState) => void) {

  }

  hideFooter(): () => void {
    this.modifyState(x => (
      { ...x, hideFooter: true }
    ));
    return () => this.modifyState(x => (
      { ...x, hideFooter: false }
    ));
  }
}

const UiServiceContext = createContext<UiService>(null!);
const UiStateContext = createContext<UiState>({} as UiState);

export function UiProvider({children}: {children: ReactNode}) {

  const [state, setState] = useState<UiState>({ hideFooter: false, hideHeader: false });
  const [service] = useState(() => new UiService(setState));

  return (
    <UiServiceContext.Provider value={service}>
      <UiStateContext.Provider value={state}>
        {children}
      </UiStateContext.Provider>
    </UiServiceContext.Provider>
  );
}

export function useUiElementState() {
  return useContext(UiStateContext);
}

export function useHideFooter() {
  const context = useContext(UiServiceContext);
  useEffect(() => context.hideFooter(), []);
}