import { HookValue } from '../util/hook-value';
import { useState } from 'react';
import { isFunction } from '@juulsgaard/ts-tools';

export interface StateSignal<T> {
  (): T;
  setValue: (state: T | ((prev: T) => T)) => void;
}

function stateSignal<T>(init: T, setState: (value: T) => void): StateSignal<T> {
  let value = init;
  const signal: StateSignal<T> = () => value;
  signal.setValue = (update) => {
    value = isFunction(update) ? update(value) : update;
    setState(value);
  };
  return signal;
}

export function useSignalState<T>(init: HookValue<T>): [state: T, signal: StateSignal<T>] {
  const [state, setState] = useState<T>(init);
  const [signal] = useState(() => stateSignal(state, setState));
  return [state, signal];
}