import { createContext, ReactNode, useContext, useState } from 'react';
import { HttpClient } from './http-client';
import { useTranslations } from '../../context/init-data.context';

const HttpClientContext = createContext<HttpClient>(new HttpClient());

export function HttpClientProvider({ children }: { children: ReactNode }) {
  const {errorLabels: {errorRequest}} = useTranslations();
  const [client] = useState(() => new HttpClient({genericError: errorRequest}));

  return (
    <HttpClientContext.Provider value={client}>
      {children}
    </HttpClientContext.Provider>
  )
}

export function useHttpClient() {
  return useContext(HttpClientContext);
}