import React, { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import AppInitModel from '../Models/App/AppInitModel.interface';
import { isSSR } from '../lib/util/is-ssr';
import { HttpClient } from '../lib/requests/http-client';
import { useHttpClient } from '../lib/requests/HttpClientProvider';

const AppInitContext = createContext<AppInitModel>({} as AppInitModel);

function loadAppInit(client: HttpClient) {
  return client.get('api://init/app').go<AppInitModel>();
}

interface Props {
  children: ReactNode;
  initData: AppInitModel | undefined;
}

export function InitLoader({ children, initData }: Props) {
  const [data, setData] = useState(initData);
  const hasData = useMemo(() => !!data, [data]);

  const client = useHttpClient();
  useEffect(() => {
    if (isSSR()) return;

    if (hasData) return;

    const req = loadAppInit(client);
    req.then(setData);

    // Cancel request in strict mode
    return () => req.cancel();
  }, [hasData]);

  if (!data) return <></>;

  return (
    <AppInitContext.Provider value={data}>
      {children}
    </AppInitContext.Provider>
  );
}

export const useAppInit = () => {
  return React.useContext(AppInitContext);
};

export const useAppSettings = () => {
  return useAppInit().appSettings;
};

export const useTranslations = () => {
  return useAppInit().translations;
};