import { globalCss } from '@stitches/react';

let origin;

if (typeof window !== 'undefined') {
  origin = window.location.origin;
}

const globalStyles = globalCss({
  // !!! Keep in mind in all instances of primary font, -25% word spacing needs to be applied to match design. !!!
  // Use variable $wordSpacings$fontPrimary combined with primary font
  '@font-face': [
    {
      fontFamily: 'fontRegular',
      src: `url("${origin}/dist/fonts/PlusJakartaSans-Regular.ttf") format("truetype")`,
      fontDisplay: 'swap',
    },
    {
      fontFamily: 'fontExtraLight',
      src: `url("${origin}/dist/fonts/PlusJakartaSans-ExtraLight.ttf") format("truetype")`,
      fontDisplay: 'swap',
    },
    {
      fontFamily: 'fontLight',
      src: `url("${origin}/dist/fonts/PlusJakartaSans-Light.ttf") format("truetype")`,
      fontDisplay: 'swap',
    },
    {
      fontFamily: 'fontMedium',
      src: `url("${origin}/dist/fonts/PlusJakartaSans-Medium.ttf") format("truetype")`,
      fontDisplay: 'swap',
    },
    {
      fontFamily: 'fontBold',
      src: `url("${origin}/dist/fonts/PlusJakartaSans-Bold.ttf") format("truetype")`,
      fontDisplay: 'swap',
    },
    {
      fontFamily: 'fontSemiBold',
      src: `url("${origin}/dist/fonts/PlusJakartaSans-SemiBold.ttf") format("truetype")`,
      fontDisplay: 'swap',
    },
    {
      fontFamily: 'fontExtraBold',
      src: `url("${origin}/dist/fonts/PlusJakartaSans-ExtraBold.ttf") format("truetype")`,
      fontDisplay: 'swap',
    },
    // TODO: Move into SCSS files when build process is fixed on Linux
    {
      fontFamily: 'Jakarta',
      src: `url("${origin}/dist/fonts/PlusJakartaSans-Light.ttf") format("truetype")`,
      fontDisplay: 'swap',
      fontWeight: 300
    },
    {
      fontFamily: 'Jakarta',
      src: `url("${origin}/dist/fonts/PlusJakartaSans-Regular.ttf") format("truetype")`,
      fontDisplay: 'swap',
      fontWeight: 400
    },
    {
      fontFamily: 'Jakarta',
      src: `url("${origin}/dist/fonts/PlusJakartaSans-Medium.ttf") format("truetype")`,
      fontDisplay: 'swap',
      fontWeight: 500
    },
    {
      fontFamily: 'Jakarta',
      src: `url("${origin}/dist/fonts/PlusJakartaSans-Bold.ttf") format("truetype")`,
      fontDisplay: 'swap',
      fontWeight: 700
    },
  ],
  html: {
    lineHeight: 1.15,
    webkitTextSizeAdjust: '100%',
    fontSize: '1rem',
    height: '100%',
  },
  body: {
    margin: 0,
    padding: 0,
    webkitFontSmoothing: 'antialiased',
    mozOsxFontSmoothing: 'grayscale',
    textRendering: 'optimizeLegibility',
    overflowX: 'hidden',
    minHeight: '100%',
    color: '$onSurface',
    '&.hide-scrollbar::-webkit-scrollbar': {
      backgroundColor: 'transparent',
    },
  },
  h1: {
    fontSize: '2em',
    margin: '0.67em 0',
  },
  hr: {
    boxSizing: 'content-box',
    borderTopWidth: '1px',
    height: 0,
    overflow: 'visible',
  },
  pre: {
    fontFamily: 'monospace, monospace',
    fontSize: '1em',
  },
  a: {
    backgroundColor: 'transparent',
    color: 'inherit',
    textDecoration: 'inherit',
  },
  'abbr[title]': {
    borderBottom: 'none',
    webkitTextDecoration: 'underline dotted',
    textDecoration: 'underline dotted',
  },
  b: {
    fontSeight: 'bolder',
  },
  strong: {
    fontSeight: 'bolder',
  },
  code: {
    fontFamily: 'monospace, monospace',
    fontSize: '1em',
  },
  kbd: {
    fontFamily: 'monospace, monospace',
    fontSize: '1em',
  },
  samp: {
    fontFamily: 'monospace, monospace',
    fontSize: '1em',
  },
  small: {
    fontSize: '80%',
  },
  sub: {
    fontSize: '75%',
    lineHeight: 0,
    position: 'relative',
    verticalAlign: 'baseline',
    bottom: '-0.25em',
  },
  sup: {
    fontSize: '75%',
    lineHeight: 0,
    position: 'relative',
    verticalAlign: 'baseline',
    top: '-0.5em',
  },
  img: {
    borderStyle: 'solid',
  },
  'button, input, optgroup, select, textarea': {
    fontFamily: 'inherit',
    fontSize: '100%',
    lineHeight: 'inherit',
    margin: 0,
    padding: 0,
    color: 'inherit',
  },
  'button, input': {
    /* 1 */
    overflow: 'visible',
  },
  'button, select': {
    textTransform: 'none',
  },
  'button, [type="button"], [type="reset"], [type="submit"]': {
    webkitAppearance: 'button',
  },
  'button::-moz-focus-inner, [type="button"]::-moz-focus-inner, [type="reset"]::-moz-focus-inner, [type="submit"]::-moz-focus-inner':
  {
    borderStyle: 'none',
    padding: 0,
  },
  'button:-moz-focusring, [type="button"]":-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring':
  {
    outline: '1px dotted ButtonText',
  },
  fieldset: {
    padding: '0.35em 0.75em 0.625em',
    margin: 0,
  },
  legend: {
    boxSizing: 'border-box',
    color: 'inherit',
    display: 'table',
    maxWidth: '100%',
    padding: 0,
    whiteSpace: 'normal',
  },
  progress: {
    verticalAlign: 'baseline',
  },
  textarea: {
    overflow: 'auto',
    resize: 'vertical',
  },
  '[type="checkbox"], [type="radio"]': {
    boxSizing: 'border-box',
    padding: 0,
  },
  '[type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button':
  {
    height: 'auto',
  },
  '[type="search"]': {
    webkitAppearance: 'textfield',
    outlineOffset: '-2px',
  },
  '[type="search"]::-webkit-search-decoration': {
    webkitAppearance: 'none',
  },
  '::-webkit-file-upload-button': {
    webkitAppearance: 'button',
    font: 'inherit',
  },
  details: {
    display: 'block',
  },
  summary: {
    display: 'list-item',
  },
  template: {
    display: 'none',
  },
  '[hidden]': {
    display: 'none',
  },
  'blockquote, dl, dd, h4, h5, h6, hr, figure, p': {
    margin: 0,
  },
  button: {
    backgroundColor: 'transparent',
    backgroundImage: 'none',
    padding: 0,
  },
  'ol, ul': {
    listStyle: 'none',
    margin: 0,
    padding: 0,
  },
  '*, ::before, ::after': {
    boxSizing: 'border-box',
    borderWidth: 0,
    borderColor: '#e2e8f0',
  },
  'input:-ms-input-placeholder, textarea:-ms-input-placeholder': {
    color: '#a0aec0',
  },
  'input::-ms-input-placeholder, textarea::-ms-input-placeholder': {
    color: '#a0aec0',
  },
  'input::placeholder, textarea::placeholder': {
    color: '#a0aec0',
  },
  'button, [role="button"]': {
    cursor: 'pointer',
  },
  table: {
    borderCollapse: 'collapse',
  },

  'h1, h2, h3, h4, h5, h6': {
    fontWeight: 'inherit',
  },
  'pre, code, kbd, samp': {
    fontFamily:
      'Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace',
  },
  'img, svg, video, canvas, audio, iframe, embed, object': {
    display: 'block',
    verticalAlign: 'middle',
  },
  'img, video': {
    maxWidth: '100%',
    height: 'auto',
  },
  'html.wf-active body': {
    fontFamily: 'fontRegular, Arial',
    lineHeight: 1.625,
  },
  '#root': {
    backgroundColor: '$surface',
  },
  ':focus:not(:focus-visible)': {
    outline: 'none',
  },
  '*:focus-visible': {
    outlineOffset: '2px',
    outline: 'auto 2px $focusBorder',
  },
});

export default globalStyles;
