let timer: NodeJS.Timeout | null = null;

// TODO: NO!
export const debounce = (fn: () => void, delay: number, abort = false) => {
  if (timer) {
    clearTimeout(timer);
  }

  timer = setTimeout(() => {
    if (abort) return;
    fn();
  }, delay);
};
