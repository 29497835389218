import './imports';

import { hydrate } from 'react-dom';
import { createRoot } from 'react-dom/client';
import { mutate } from 'swr';
import { loadableReady } from '@loadable/component';
import App from './App';
import PageInitModel from './Models/App/PageInitModel.interface';

const state = (window as unknown as { __APP_INIT_DATA__: PageInitModel }).__APP_INIT_DATA__ as PageInitModel;

const rootElement = document.getElementById('root');
if (rootElement === null) throw Error('No root element');

if (rootElement.children[0]) {
  loadableReady(() => {
    hydrate(<App initData={state} initPage={state.currentPage} initSession={state.userSession} />, rootElement);
  });
} else {
  const root = createRoot(rootElement);
  root.render(<App initData={state} initPage={state.currentPage} initSession={state.userSession} />);
}

// Listen to the "beta/epiReady" event to update the "context" property.
window.addEventListener('load', () => {
  // Expect "epi" to be there after the "load" event. If it's not then we're
  // not in any editing context.
  let updateTimer: number;
  const { epi } = window as any;
  if (!epi) {
    return;
  }

  function setContext() {
    epi.publish('beta/domUpdated');
    // Subscribe to *all* property updates in on-page edit mode
    epi.subscribe('beta/contentSaved', function (propertyDetails: any) {
      // Ignore update if it wasn't successful, for example if there were validation errors
      if (!propertyDetails.successful) {
        return;
      }
      // fetch new complete data and update whole state.
      clearTimeout(updateTimer);
      updateTimer = window.setTimeout(() => {
        mutate('epiedit');
      }, 500);
    });
  }

  // Check for beta and that ready is an actual true value (not just truthy).
  if (epi.beta && epi.beta.ready === true) {
    // "beta/epiReady" already fired.
    setContext();

    // The subscribe method won't be available in View mode.
  } else if (epi.subscribe) {
    epi.subscribe('beta/epiReady', () => {
      setContext();
    });
  }
});
