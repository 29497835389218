import { createContext, ReactNode, useContext } from 'react';
import BlockModelBase from '../Models/Blocks/Base/BlockModelBase.interface';

const BlockDataContext = createContext({} as BlockModelBase);

interface Props {
  children: ReactNode;
  data: BlockModelBase;
}

export function BlockDataProvider({ children, data }: Props) {
  return (
    <BlockDataContext.Provider value={data}>
      {children}
    </BlockDataContext.Provider>
  )
}

export function useBlockData<T extends BlockModelBase = BlockModelBase>(): T {
  return useContext(BlockDataContext) as T;
}