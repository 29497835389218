import HeaderModel from 'Models/Headers/HeaderModel.interface';
import StartPageModel from 'Models/Pages/StartPage/StartPageModel.interface';
import React, { useMemo } from 'react';
import useMedia from 'Shared/Hooks/useMedia';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import loadable from '@loadable/component';
import { canUseDOM } from 'Shared/DOM/WindowHelper';
import { useUiElementState } from '../../../context/ui.context';
import { useAppInit } from '../../../context/init-data.context';
import { usePageData } from '../../../context/page-data.context';

// TODO: These don't need to be separate
const MobileHeader = loadable(
  () => import(/* webpackPrefetch: true */ './MobileHeader/MobileHeader')
);
const DesktopHeader = loadable(
  () => import(/* webpackPrefetch: true */ './DesktopHeader/DesktopHeader')
);

const noHeader = new Set<string>(['CheckoutPage', 'OrderConfirmationPage']);

export const HeaderContext = React.createContext({});

function Header() {
  // TODO: Can we use the init data for the lifetime of the header?
  const headerData = useAppInit().header;

  const { inEditMode, pageType } = usePageData<StartPageModel>();
  const isDesktop = useMedia(mediaQueryTypes.bpMin721);

  const { hideHeader } = useUiElementState();

  const showHeader = useMemo(() => !hideHeader && !!pageType && !noHeader.has(pageType), [hideHeader, pageType]);

  if (canUseDOM()) {
    const { siteLogotype } = headerData ?? {};
    // TODO: No. Just no!
    localStorage.setItem('logoUrl', siteLogotype?.src ?? '');
  }

  if (!showHeader) return <></>;

  return (
    <HeaderContext.Provider value={headerData}>
      {isDesktop || inEditMode ? <DesktopHeader /> : <MobileHeader />}
    </HeaderContext.Provider>
  );
}

const useHeaderData = () => {
  return React.useContext(HeaderContext) as HeaderModel;
};

export { Header, useHeaderData };
