import { BlockDataProvider } from './block.context';
import BlockModelBase from '../Models/Blocks/Base/BlockModelBase.interface';
import { RouteStore } from './route-store';
import { blocks } from './blocks';
import { useEffect, useMemo, useRef } from 'react';
import { useBlockLoading } from './PageRenderer';
import { LoadingState } from '../lib/util/loading-service';
import { isLoadable } from './util';
import { isBool } from '@juulsgaard/ts-tools';

const store = new RouteStore(blocks);

interface Props {
  blockType: keyof typeof blocks | string;
  content: BlockModelBase;
}

// TODO: Do we want deferred loading for when the block is on screen?
export function BlockRenderer({ blockType, content }: Props) {

  const loading = useRef<LoadingState | undefined>();
  const loadingService = useBlockLoading();

  const Block = useMemo(
    () => store.getComponent(blockType),
    [blockType]
  );

  useEffect(() => {
    const result = store.getLoadingState(blockType);

    if (isBool(result)) {
      loading?.current?.cancel();
      return;
    }

    if (!loading.current?.loading) {
      loading.current = loadingService.startLoad();
    }

    let disposed = false;
    result.finally(() => {
      if (disposed) return;
      loading?.current?.cancel();
    });
    return () => {disposed = false};
  }, [blockType]);

  useEffect(() => () => loading.current?.cancel(), []);

  // TODO: Handle block not existing
  if (!Block) return <></>;

  // TODO: Block loading placeholder?
  const block = isLoadable(Block) ? <Block fallback={<></>} /> : <Block />;

  return (
    <BlockDataProvider data={content}>
      {block}
    </BlockDataProvider>
  );
}