import { createContext, ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { useSignalState } from '../hooks/use-signal-state';
import { isSSR } from '../util/is-ssr';
import { Router, RouteState } from './router';
import { parseFragmentStr, parseQueryStr, parseRouteStr } from './route-parsing';
import { RouteSnapshot } from './route-snapshot';
import { useWindowEvent } from '../hooks/use-event';
import { ScrollHistory } from './scroll-history';

const RouterContext = createContext<Router>({} as Router);
const RouteContext = createContext<RouteState>({} as RouteState);
const SnapshotContext = createContext<RouteSnapshot>({} as RouteSnapshot);

export function RouterProvider({ children }: { children: ReactNode }) {

  useEffect(() => {
    if (!isSSR() && window.history.scrollRestoration) {
      window.history.scrollRestoration = 'manual';
    }
  }, []);

  const [routeStr, routeStrSignal] = useSignalState(() => {
    if (isSSR()) return '';
    return window.location.pathname;
  });

  const [queryStr, queryStrSignal] = useSignalState(() => {
    if (isSSR()) return '';
    return window.location.search;
  });

  const [fragmentStr, fragmentStrSignal] = useSignalState(() => {
    if (isSSR()) return '';
    return window.location.hash;
  });

  const route = useMemo(() => parseRouteStr(routeStr), [routeStr.trim().toLowerCase()]);
  const query = useMemo(() => parseQueryStr(queryStr), [queryStr.trim().toLowerCase()]);
  const fragment = useMemo(() => parseFragmentStr(fragmentStr), [fragmentStr.trim().toLowerCase()]);

  const [scrollHistory] = useState(() => new ScrollHistory());

  const [snapshot, snapshotSignal] = useSignalState<RouteSnapshot>(() => {
    return new RouteSnapshot(route, query, fragment, scrollHistory.getOffset());
  });

  const [router] = useState(() => new Router(scrollHistory, snapshotSignal, routeStrSignal, queryStrSignal, fragmentStrSignal));
  useWindowEvent('popstate', router.onPopState);

  const value = useMemo(
    () => (
      { route, query, fragment }
    ),
    [route, query, fragment]
  );

  return (
    <RouterContext.Provider value={router}>
      <RouteContext.Provider value={value}>
        <SnapshotContext.Provider value={snapshot}>
          {children}
        </SnapshotContext.Provider>
      </RouteContext.Provider>
    </RouterContext.Provider>
  );
}

export function useRouter() {
  return useContext(RouterContext);
}

export function useRoute() {
  return useContext(RouteContext);
}

export function useRouteSnapshot() {
  return useContext(SnapshotContext);
}