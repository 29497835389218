import { isFunction } from '@juulsgaard/ts-tools';

export type HookValue<T> = T | (() => T);

export function getHookValue<T>(value: HookValue<T>): T;
export function getHookValue<T>(value: HookValue<T>|undefined): T|undefined;
export function getHookValue<T>(value: HookValue<T>|undefined): T|undefined {
  if (!value) return undefined;
  if (isFunction(value)) return value();
  return value;
}