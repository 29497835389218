import { createContext, useContext } from 'react';

export interface SnackbarData {
  message: string;
  title?: string;
  duration: number;
  type: 'error' | 'success' | 'info';
}

export abstract class SnackbarService {
  abstract showError(message: string, title?: string): void;
}

export class SnackbarController extends SnackbarService {

  private callback?: (data: SnackbarData | undefined) => void;

  register(callback: (data: SnackbarData | undefined) => void) {
    this.callback = callback;
  }

  unregister() {
    this.callback = undefined;
  }

  showError(message: string, title?: string): void {
    this.callback?.({
      type: 'error',
      message,
      title,
      duration: 15000
    });
  }
}

export const SnackbarContext = createContext<SnackbarService>(new SnackbarController());

export function useSnacks(): SnackbarService {
  return useContext(SnackbarContext);
}