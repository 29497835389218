import { isString, mapObj } from '@juulsgaard/ts-tools';
import { StateSignal } from '../hooks/use-signal-state';
import { parseFragmentStr, parseQueryStr, parseRouteStr } from './route-parsing';
import { ScrollHistory } from './scroll-history';
import { RouteSnapshot } from './route-snapshot';

export interface NavigateOptions {
  fragment?: string;
  query?: Record<string, unknown>;
  replace?: boolean;
  force?: boolean;
}

export interface StrNavigateOptions {
  replace?: boolean;
}

export interface RouteState {
  readonly route: string[];
  readonly query: Map<string, string>;
  readonly fragment: string | undefined;
}

export class Router implements RouteState {

  // TODO: Update scroll history on scroll events as well
  constructor(
    private readonly history: ScrollHistory,
    private readonly snapshotSignal: StateSignal<RouteSnapshot>,
    private readonly routeStrSignal: StateSignal<string>,
    private readonly queryStrSignal: StateSignal<string>,
    private readonly fragmentStrSignal: StateSignal<string>
  ) { }

  get route() {
    return parseRouteStr(this.routeStrSignal());
  }

  get query() {
    return parseQueryStr(this.queryStrSignal());
  }

  get fragment() {
    return parseFragmentStr(this.fragmentStrSignal());
  }

  private pushState(route: string | URL) {
    this.history.update(window.scrollY);
    const id = Date.now();
    this.history.forwardNav();
    window.history.pushState({id}, '', route);
  }

  private replaceState(route: string | URL) {
    window.history.replaceState(window.history.state, '', route);
  }

  private schedulePageLoad(snapshot: RouteSnapshot) {
    if (snapshot.equals(this.snapshotSignal())) {
      snapshot.skipped();
    }

    this.snapshotSignal.setValue(snapshot);
  }

  private onNavigation(): Promise<boolean> {
    this.updateState();

    return new Promise<boolean>(resolve => {
      const snapshot = new RouteSnapshot(this.route, this.query, this.fragment, undefined, resolve);
      this.schedulePageLoad(snapshot);
    })
  }

  private _resolveBack?: (success: boolean) => void;

  // eslint-disable-next-line unused-imports/no-unused-vars
  readonly onPopState = (event: PopStateEvent) => {
    this.updateState();

    const snapshot = new RouteSnapshot(this.route, this.query, this.fragment, this.history.getOffset(), this._resolveBack);
    this._resolveBack = undefined;
    this.schedulePageLoad(snapshot);
  }

  private getCurrentUrl() {
    let url = location.pathname;
    if (location.search) url += `?${location.search}`;
    if (location.hash) url += `#${location.hash}`;
    return url;
  }

  private updateState() {
    this.routeStrSignal.setValue(window.location.pathname);
    this.queryStrSignal.setValue(window.location.search);
    this.fragmentStrSignal.setValue(window.location.hash);
  }

  goBack() {
    const promise = new Promise(resolve => {
      this._resolveBack = resolve;
    });
    window.history.back();
    return promise;
  }

  navigate(url: string[]|string, options?: NavigateOptions) {
    const segments = isString(url) ? parseRouteStr(url) : url;
    let route = '/' + segments.join('/');
    if (options?.query) route += `?${new URLSearchParams(mapObj(options.query, x => String(x))).toString()}`;
    if (options?.fragment) route += `#${options.fragment}`;

    if (!options?.force && route === this.getCurrentUrl()) {
      return Promise.resolve(false);
    }

    if (options?.replace) {
      this.replaceState(route);
    } else {
      this.pushState(route);
    }

    return this.onNavigation();
  }

  navigateByStr(url: string, options?: StrNavigateOptions) {

    if (url === this.getCurrentUrl()) {
      return Promise.resolve(false);
    }

    if (options?.replace) {
      this.replaceState(url);
    } else {
      this.pushState(url);
    }

    return this.onNavigation();
  }

  goHome() {
    return this.navigate([]);
  }

  reload() {
    this.history.update(window.scrollY);
    location.reload();
  }
}