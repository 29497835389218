import { usePageData } from '../../../context/page-data.context';
import ErrorPageModel from '../../../Models/Pages/Base/ErrorPageModel.interface';
import { GenericError } from '../../../components/GenericError/GenericError';
import ContentContainer from '../../../DesignComponents/Molecules/ContentContainer/ContentContainer';
import ContentArea from '../../../DesignComponents/Organisms/ContentArea/ContentArea';

function NotFoundPage() {
  const { heading, bodyText, errorMessage, statusCode, contentArea } = usePageData<ErrorPageModel>();
  return (
    <GenericError heading={heading} bodyText={bodyText} errorMessage={errorMessage} statusCode={statusCode}>
      {
        contentArea &&
        <ContentContainer>
          <ContentArea items={contentArea} />
        </ContentContainer>

      }
    </GenericError>
  );
}

export default NotFoundPage;
