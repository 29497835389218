
export function parseRouteStr(str: string) {
  if (!str) return [];
  return str.trim().split('/').filter(x => x.length > 0);
}

export function parseQueryStr(str: string) {
  const params = new URLSearchParams(str);
  const map = new Map<string, string>();
  params.forEach((value, key) => map.set(key, value));
  return map;
}

export function parseFragmentStr(str: string) {
  return str?.substring(1) || undefined;
}