import KexReporterOverlay from './Kex/KexReporterOverlay';
import Breadcrumb from './DesignComponents/Molecules/Breadcrumb/Breadcrumb';
import { Header } from './DesignComponents/Organisms/Header/Header';
import ErrorHandler from './components/ErrorHandler';
import globalStyles from './DesignSystem/Styles/global.stitches';
import { UiStateProvider } from 'Shared/Providers/UiState/UiStateProvider';
import PageMetaData from 'Shared/SEO/PageMetaData';
import { HelmetProvider } from 'react-helmet-async';
import { FilterProvider } from 'context/filter.context';
import GenericModal from 'DesignComponents/Organisms/Modal/GenericModal/GenericModal';
import { SnackbarProvider } from './lib/snackbars/SnackbarProvider';
import { useEffect } from 'react';
import light from './Theme/Light/light.theme';
import MatThemeProvider from './context/theme.context';
import { PageSpinner } from './components/PageSpinner';
import { UiProvider } from './context/ui.context';
import { RouterProvider } from './lib/router/router-hook';
import { PageLoader } from './context/page-data.context';
import { InitLoader } from './context/init-data.context';
import PageModelBase from './Models/Pages/Base/PageModelBase.interface';
import AppInitModel from 'Models/App/AppInitModel.interface';
import { HttpClientProvider } from './lib/requests/HttpClientProvider';
import { PageRenderer } from './routing/PageRenderer';
import { isSSR } from './lib/util/is-ssr';
import { HttpClientError } from './lib/requests/request-error';
import Footer from './DesignComponents/Organisms/Footer/Footer';

import './styles.scss';
import QuickNavigator from './Shared/QuickNavigator/QuickNavigator';
import { SessionLoader } from './context/session-data.context';
import UserSessionModel from './Models/App/UserSessionModel.interface';

if (!isSSR()) {
  // Silence rethrown HTTP exceptions
  window.addEventListener('unhandledrejection', event => {
    if (event.reason instanceof HttpClientError) event.preventDefault();
  });
}

function App({ initData, initPage, initSession }: {
  initData?: AppInitModel,
  initPage?: PageModelBase,
  initSession?: UserSessionModel
}) {
  globalStyles();

  // Apply stitches theme
  useEffect(() => document.body.classList.add(light), []);

  return (
    <InitLoader initData={initData}>
      <HttpClientProvider>
        <SessionLoader initData={initSession}>
          <MatThemeProvider>
            <PageSpinner>
              <SnackbarProvider>
                <RouterProvider>
                  <FilterProvider>
                    <UiStateProvider>
                      <ErrorHandler>
                        <HelmetProvider>
                          {/*TODO: Replace with Snackbar*/}
                          <KexReporterOverlay />

                          <Page initPage={initPage} />

                          {/*TODO: Replace with MatDialog*/}
                          <GenericModal />
                        </HelmetProvider>
                      </ErrorHandler>
                    </UiStateProvider>
                  </FilterProvider>
                </RouterProvider>
              </SnackbarProvider>
            </PageSpinner>
          </MatThemeProvider>
        </SessionLoader>
      </HttpClientProvider>
    </InitLoader>
  );
}

const Page = ({ initPage }: { initPage: PageModelBase | undefined }) => {
  return (
    <UiProvider>
      <PageLoader initPage={initPage}>
        <QuickNavigator />
        <Header />
        <main id="content">
          <Breadcrumb />
          <PageMetaData />
          <PageRenderer />
        </main>
        <Footer />
      </PageLoader>
    </UiProvider>
  );
};

export default App;
