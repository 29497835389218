import { API_ROOT_PATH } from '../../Shared/Constants/route';
import { HttpClientError, parseHttpClientError, RequestError } from './request-error';
import { parseError } from '@juulsgaard/ts-tools';
import { RequestState } from './request-state';

function logError(method: string, path: string, payload: unknown | undefined, startTime: number, e: unknown) {
  const error = parseHttpClientError(e);
  const duration = Date.now() - startTime;

  const style = error.cancelled ? 'font-weight: bold; color: orange' : 'font-weight: bold; color: red';

  console.groupCollapsed(`%c${method}: ${path}${error.cancelled ? '' : ' [!!]'}`, style);
  console.log('%cError: ', style, error.message);
  console.log('%cTime: ', style, duration < 1000 ? `${duration}ms` : `${duration / 1000}s`);
  if (payload) console.log('%cPayload: ', style, payload);
  if (error instanceof RequestError) console.log('%cResponse: ', style, error.data);
  if (!error.cancelled && error.stack) console.log('%cStack: ', style, error.stack);
  if (error instanceof RequestError) console.log('%cCorrelation Id: ', style, error.correlationId);
  console.groupEnd();
}

function logSuccess(method: string, path: string, payload: unknown | undefined, response: unknown|undefined, startTime: number) {
  const duration = Date.now() - startTime;

  const style = 'font-weight: bold';

  console.groupCollapsed(`%c${method}: ${path}`, style);
  console.log('%cTime: ', style, duration < 1000 ? '< 1s' : `${duration / 1000}s`);
  if (payload) console.log('%cPayload: ', style, payload);
  if (response) console.log('%cResponse: ', style, response);
  console.groupEnd();
}

interface HttpClientContext {
  genericError: string;
}

export class HttpClient {

  private readonly context: HttpClientContext

  constructor(context?: HttpClientContext) {
    this.context = context ?? {genericError: 'Something went wrong'};
  }

  public post(url: string): HttpRequestWithBody {
    return new HttpRequestWithBody(url, 'POST', this.context);
  }

  public patch(url: string): HttpRequestWithBody {
    return new HttpRequestWithBody(url, 'PATCH', this.context);
  }

  public get(url: string): HttpRequest {
    return new HttpRequest(url, 'GET', this.context);
  }

}

export class HttpRequest {

  private static getUrl(url: string): string {
    if (url.startsWith('api://')) return url.replace('api://', API_ROOT_PATH + '/');
    return url;
  }

  protected url: string;
  protected body?: unknown;
  protected headers = new Map<string, string>();
  protected params: Record<string, string> = {};

  constructor(
    url: string,
    protected readonly method: 'GET' | 'POST' | 'PATCH' | 'PUT' | 'DELETE' | 'OPTIONS',
    protected readonly context: HttpClientContext
  ) {
    this.url = HttpRequest.getUrl(url);
  }

  public withQuery(name: string, value: unknown): this {
    this.params[name] = String(value);
    return this;
  }

  public withHeader(name: string, value: string): this {
    this.headers.set(name, value);
    return this;
  }

  public go(): RequestState;
  public go<T>(): RequestState<T>;
  public go<T, TOut>(parser: (value: T) => TOut): RequestState<TOut>;
  public go<T, TOut>(parser?: (value: T) => TOut): RequestState<T | TOut> {

    const headers = new Headers({ 'Accept': 'application/json' });

    for (const [name, value] of this.headers) {
      headers.set(name, value);
    }

    if (this.body) {
      headers.set('Content-Type', 'application/json');
    }

    let url = this.url || '/';

    const query = new URLSearchParams(this.params);
    if (query.size) {
      const prefix = url.includes('?') ? '&' : '?';
      url += prefix + query.toString();
    }

    const abort = new AbortController();

    const startTime = Date.now();

    const request = fetch(url, {
      method: this.method,
      headers,
      body: !this.body ? undefined : JSON.stringify(this.body),
      signal: abort.signal
    });

    let processed: Promise<T> | Promise<TOut> = this.processResponse<T>(request);

    if (parser) processed = processed.then<TOut, never>(parser);

    processed.then(
      x => logSuccess(this.method, url, this.body, x, startTime),
      e => logError(this.method, url, this.body, startTime, e)
    );

    processed = processed.catch(e => {
      if (e instanceof HttpClientError) throw e;
      const error = parseError(e);
      throw new HttpClientError(this.context.genericError, error);
    });

    return new RequestState<T | TOut>(processed, abort);
  }

  private async processResponse<T>(request: Promise<Response>): Promise<T> {

    const response = await request;

    if (response.ok) {
      const dataStr = await response.text();
      return dataStr ? JSON.parse(dataStr) : undefined;
    }

    const correlationId = response.headers.get('x-correlation-id') ?? undefined;
    let data: undefined | any;

    try {
      const dataStr = await response.text();
      data = dataStr ? JSON.parse(dataStr) : undefined;
    } catch (e) {
      const error = parseError(e);
      throw new RequestError(this.context.genericError, response.status, correlationId, undefined, error);
    }

    throw new RequestError(data?.error ?? this.context.genericError, response.status, correlationId, data);
  }
}

export class HttpRequestWithBody extends HttpRequest {
  constructor(
    url: string,
    method: 'POST' | 'PATCH',
    context: HttpClientContext
  ) {
    super(url, method, context);
  }

  withBody(body: unknown): this {
    this.body = body;
    return this;
  }
}