import { useCallback } from 'react';
import { useRouter } from './router-hook';

export type KexNavigateType = (
  url: string,
  event?: PopStateEvent | undefined,
  clearQueryString?: boolean
) => Promise<boolean>;

// TODO: Migrate to Router
const useKexNavigate = () => {
  const router = useRouter();
  const navigate = useCallback((
    url: string,
    event?: PopStateEvent | undefined,
    // eslint-disable-next-line unused-imports/no-unused-vars
    clearQueryString?: boolean
  ) => {
    return router.navigateByStr(url, {replace: !!event})
  }, []);

  return navigate;
};
export { useKexNavigate };