import PageLoader from 'DesignComponents/Atoms/Loaders/PageLoader/PageLoader';
import React, { createContext, ReactNode, useContext } from 'react';
import { LoadingService, useLoadingService } from '../lib/util/loading-service';

const LoadingContext = createContext<LoadingService>(null!);

export function PageSpinner({ children }: { children: ReactNode }) {

  const [loading, service] = useLoadingService();

  return (
    <LoadingContext.Provider value={service}>
      <PageLoader loading={loading} />
      {children}
    </LoadingContext.Provider>
  );
}

export function usePageSpinner() {
  return useContext(LoadingContext);
}
