import { SnackbarController, SnackbarData } from './snackbars';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Alert, AlertTitle, Snackbar } from '@mui/material';

export default function SnackbarOutlet({ controller }: { controller: SnackbarController }) {

  const next = useRef<SnackbarData|undefined>(undefined);
  const [snackbar, setSnackbar] = useState<SnackbarData | undefined>();
  const [open, setOpen] = useState(false);

  const enqueue = useCallback((data: SnackbarData|undefined) => {
    if (!data) {
      next.current = undefined;
      setSnackbar(undefined);
      setOpen(false);
      return;
    }

    if (!snackbar) {
      setSnackbar(data);
      setOpen(true);
      return;
    }

    next.current = data;
    setOpen(false);
  }, [snackbar]);

  const dequeue = useCallback(() => {
    if (!next.current) {
      setSnackbar(undefined);
      return;
    }

    setSnackbar(next.current);
    next.current = undefined;
    setOpen(true);
  }, []);

  useEffect(() => controller.register(enqueue), [enqueue]);

  if (!snackbar) return <></>;

  return (
    <Snackbar open={open} onClose={() => setOpen(false)} autoHideDuration={snackbar.duration}
              TransitionProps={{ onExited: dequeue }}>
      <Alert severity={snackbar.type} variant="filled" sx={{minWidth: 300}}>
        {snackbar.title && <AlertTitle>{snackbar.title}</AlertTitle>}
        {snackbar.message}
      </Alert>

    </Snackbar>
  );
}