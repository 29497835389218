import { isSSR } from '../util/is-ssr';
import { z } from 'zod';
import { isObject } from '@juulsgaard/ts-tools';

export class ScrollHistory {

  private readonly history: Map<number, number> = new Map();

  constructor() {
    if (isSSR()) return;

    const str = sessionStorage.getItem('scrollHistory');

    if (!str) return;

    try {
      const obj = JSON.parse(str);
      this.history = new Map(zHistory.parse(obj));
    } catch (e) {
      console.warn('Failed to load scroll history', e);
      this.saveState();
    }
  }

  private saveState() {
    sessionStorage.setItem('scrollHistory', JSON.stringify([...this.history]));
  }

  private getId() {
    if (isObject(window.history.state) && 'id' in window.history.state) {
      return Number(window.history.state.id);
    }

    const state = isObject(window.history.state) ? window.history.state : {};
    const id = Date.now();
    window.history.replaceState({...state, id}, '', window.location.href);
    return id;
  }


  update(offset: number) {
    if (isSSR()) return;
    this.history.set(this.getId(), offset);
    this.saveState();
  }

  /** When navigating history loses "future" states, and so we remove them from the cache */
  forwardNav() {
    const currentId = this.getId();
    let mutated = false;

    for (const id of this.history.keys()) {
      if (id <= currentId) continue;
      this.history.delete(id);
      mutated = true;
    }

    if (mutated) this.saveState();
  }

  getOffset() {
    if (isSSR()) return 0;
    return this.history.get(this.getId()) ?? 0;
  }
}

const zHistory = z.array(z.tuple([z.number(), z.number()]));