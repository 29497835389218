import MagazineCategoryPageModel from 'Models/Pages/MagazineCategoryPage/MagazineCategoryPageModel.interface';
import ArticleProductPageModel from 'Models/Pages/ProductPage/ArticleProductPageModel.interface';
import GiftCardProductPageModel from 'Models/Pages/ProductPage/GiftCardProductPageModel.interface';
import OrderConfirmationPageModel from 'Models/Pages/OrderConfirmationPage/OrderConfirmationPageModel.interface';
import GoogleTagManagerEvent from 'Models/GoogleTagManager/GoogleTagManagerEvent.interface';
import { canUseDOM } from 'Shared/DOM/WindowHelper';
import SubscriptionOfferProductPageModel
  from 'Models/Pages/ProductPage/SubscriptionOffer/SubscriptionOfferProductPageModel.interface';
import PageModelBase from '../../Models/Pages/Base/PageModelBase.interface';

function gtmHandlePageEvent(page: PageModelBase) {
  viewPage(page);

  switch (page.pageType) {
    case 'MagazinePage':
      gtmPushEvent((page as MagazineCategoryPageModel).viewItemEvent);
      break;
    case 'SubscriptionOfferProductPage':
      gtmPushEvent((page as SubscriptionOfferProductPageModel).viewItemEvent);
      break;
    case 'ArticleProductPage':
      gtmPushEvent((page as ArticleProductPageModel).viewItemEvent);
      break;
    case 'GiftCardProductPage':
      gtmPushEvent((page as GiftCardProductPageModel).viewItemEvent);
      break;
    case 'OrderConfirmationPage':
      gtmPushEvent((page as OrderConfirmationPageModel).purchaseEvent);
      break;
    default:
      break;
  }
}

function gtmPushEvent(event: GoogleTagManagerEvent | null | undefined) {
  if (canUseDOM() && event && event.ecommerceData) {
    window.dataLayer = window.dataLayer || [];
    event.ecommerceData.event = event.eventId;
    window.dataLayer.push(event.ecommerceData);
    event = null;
  }
}

/**
 * The Page Impression data layer push has to be made AFTER the page has been drawn and the URL has been updated.
 * If the page has not been drawn then there has not been an impression, and some advertising scripts still rely on scraping the url from the address bar.
 */
export function viewPage(page: PageModelBase) {
  if (!canUseDOM()) return;
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'page_view',
    page_title: page.pageTitle,
    page_location: window.location.href,
    page_path: window.location.pathname + window.location.search,
    page_referrer: document.referrer || ''
  });

}

export { gtmPushEvent, gtmHandlePageEvent };
