import { parseError } from '@juulsgaard/ts-tools';
import { HttpClientError, parseHttpClientError } from './request-error';

export class RequestState<T = void> {

  get request(): Promise<T> {return this._request}

  private _result?: T;
  get result() {return this._result}

  private _loading = true;
  get loading() {return this._loading}

  private _error?: Error;
  get error() {return this._error}
  get failed() {return !!this._error}

  constructor(
    private readonly _request: Promise<T>,
    private readonly _abortController?: AbortController,
  ) {
    _request.finally(() => this._loading = false);
    _request.then(x => this._result = x);
    _request.catch(e => this._error = parseError(e));
  }

  cancel() {
    if (!this.loading) return;
    this._abortController?.abort('Request was manually cancelled');
  }

  then(next: (data: T) => void, error?: (error: HttpClientError) => void): this {
    this.request.then(next, !error ? undefined : e => error(parseHttpClientError(e)));
    return this;
  }

  catch(func: (error: HttpClientError) => void): this {
    this.request.catch(e => func(parseHttpClientError(e)));
    return this;
  }

  finally(func: () => void): this {
    this.request.finally(func);
    return this;
  }

}

