import { ReactNode, useRef } from 'react';
import { SnackbarContext, SnackbarController } from './snackbars';
import SnackbarOutlet from './SnackbarOutlet';

export function SnackbarProvider({ children }: { children: ReactNode }) {

  const controller = useRef(new SnackbarController());

  return (
    <>
      <SnackbarOutlet controller={controller.current} />
      <SnackbarContext.Provider value={controller.current}>
        {children}
      </SnackbarContext.Provider>
    </>
  );
}