/*
 * export event types
 */
export const ON_NAVIGATE = '1';
export const ON_KEX_ERROR = '2';
export const ON_FIXED_CIRCLE_LOADING = '3';
export const ON_PAGE_CHANGED = '4';
export const ON_SCROLL_TO_ELEMENT_FIRED = '5';
//Mobile quick search
export const ON_MOBILE_QUICKSEARCH_TOGGLE = '8';
//Minicart events
export const ON_OPEN_MINICART = '9';
//Search events
export const ON_SEARCH = '10';
export const SEARCH_ON_SEARCH_PAGE = '10.1';
export const SEARCH_SET_MANUALLY = '10.2';
//Theme
export const SET_THEME = '11';
//Theme
export const SET_CURRENT_VARIANT = '12';
export const CLEAR_FILTER = '12';

export const UPDATE_URL = '22';

type EventsType = {
  [key: string]: any;
};

export const EventDispatcher = (function () {
  const events: EventsType = {};
  /**
   * Dispatches an event if it exists
   * Example usage: EventDispatcher.dispatch('namechanged', { name: 'John' });
   * @param {*} event to dispatch
   * @param {*} data to send with the event
   */
  const dispatch = (event: any, ...data: any) => {
    if (!events[event]) return; // no one is listening to this event
    for (let i = 0; i < events[event].length; i += 1) {
      events[event][i](...data);
    }
  };

  /**
   * Subscribes to an event
   * Example usage: EventDispatcher.subscribe('namechanged', function(data) { alert(data.name); });
   * @param {*} event to subscribe to
   * @param {*} callback to call on dispatch
   */
  const subscribe = (event: any, callback: any) => {
    if (!events[event]) events[event] = []; // new event
    events[event] = [...events[event], callback];
  };

  /**
   * Unsubscribes a function to an event.
   * @param {*} event to unsubscribe to
   * @param {*} callback to remove from event
   */
  const unsubscribe = (event: any, callback: any) => {
    if (events[event] && events[event].length === 1) {
      delete events[event];
    } else {
      events[event] = events[event].filter((fn: any) => fn !== callback);
    }
  };

  return {
    dispatch,
    subscribe,
    unsubscribe,
  };
})();
