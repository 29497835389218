import { RefObject, useEffect } from 'react';
import { isSSR } from '../util/is-ssr';
import { getHookValue, HookValue } from '../util/hook-value';

export function useWindowEvent<T extends keyof WindowEventMap>(
  type: T,
  callback: (event: WindowEventMap[T]) => void,
  options?: AddEventListenerOptions
) {
  return useEvent(() => window, type, callback, options);
}

export function useDocumentEvent<T extends keyof DocumentEventMap>(
  type: T,
  callback: (event: DocumentEventMap[T]) => void,
  options?: AddEventListenerOptions
) {
  return useEvent(() => document, type, callback, options);
}

export function useEvent<T extends keyof WindowEventMap>(
  target: HookValue<Window>,
  type: T,
  callback: (event: WindowEventMap[T]) => void,
  options?: AddEventListenerOptions
): void;
export function useEvent<T extends keyof DocumentEventMap>(
  target: HookValue<Document>,
  type: T,
  callback: (event: DocumentEventMap[T]) => void,
  options?: AddEventListenerOptions
): void;
export function useEvent<T extends keyof HTMLElementEventMap>(
  target: HookValue<HTMLElement>,
  type: T,
  callback: (event: HTMLElementEventMap[T]) => void,
  options?: AddEventListenerOptions
): void;
export function useEvent<T extends keyof HTMLElementEventMap>(
  target: HookValue<RefObject<HTMLElement>>,
  type: T,
  callback: (event: HTMLElementEventMap[T]) => void,
  options?: AddEventListenerOptions
): void;
export function useEvent(
  targetIn: HookValue<EventTarget> | HookValue<RefObject<EventTarget>>,
  type: string,
  callback: (event: Event) => void,
  options?: AddEventListenerOptions
) {
  useEffect(() => {
    if (isSSR()) return;

    const targetRef = getHookValue(targetIn);
    const target = 'current' in targetRef ? targetRef.current : targetRef;
    if (!target) return;

    target.addEventListener(type, callback, options);
    return () => target.removeEventListener(type, callback, options);
  }, []);
}