import { ComponentType } from 'react';
import { objToMap } from '@juulsgaard/ts-tools';
import { LoadableComponent } from '@loadable/component';

export type Routes = Record<string, LoadableComponent<any>>;

export class RouteStore {

  private readonly routes: Map<string, LoadableComponent<any>>;
  private readonly loading = new Map<string, Promise<ComponentType>>();
  private readonly cache = new Map<string, ComponentType>();

  constructor(routes: Routes) {
    this.routes = objToMap(routes)
  }

  private startLoad(route: string, match: LoadableComponent<any>) {
    const loading = match.load().then(x => (x as any).default);
    this.loading.set(route, loading);

    loading.then(x => this.cache.set(route, x));
    loading.finally(() => this.loading.delete(route));

    return loading;
  }

  getComponent(route: string): ComponentType|LoadableComponent<any>|undefined {
    const component = this.cache.get(route);
    if (component) return component;

    const match = this.routes.get(route);
    if (!match) return undefined;

    const loading = this.loading.get(route);
    if (loading) return match;

    this.startLoad(route, match);

    return match;
  }

  getLoadingState(route: string) {
    const component = this.cache.get(route);
    if (component) return true;

    const loading = this.loading.get(route);
    if (loading) return loading;

    const match = this.routes.get(route);
    if (!match) return false;

    return this.startLoad(route, match);
  }
}