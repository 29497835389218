import React, { Component, ErrorInfo, ReactNode } from 'react';
import NotFoundPage from '../Cms/Pages/NotFoundPage/NotFoundPage';

type Props = {
  children: ReactNode;
};

type State = {
  error?: Error
};

class ErrorHandler extends Component<Props, State> {

  constructor(props: Props) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error: Error) {
    return { error: error };
  }

  componentDidCatch(error: Error, info: ErrorInfo) {
    console.error('Uncaught exception in React', error, info);
  }

  render() {
    const { error } = this.state;

    if (error !== undefined) {
      // TODO: Replace with dynamic error component (not page)
      return <NotFoundPage/>;
    }

    return <>{this.props.children}</>;
  }
}

export default ErrorHandler;
