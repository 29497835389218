import useMedia from 'Shared/Hooks/useMedia';
import { styled } from 'Theme/stitches.config';
import { mediaQueryTypes } from 'Theme/Settings/mediaQueries';
import PageModelBase from 'Models/Pages/Base/PageModelBase.interface';
import { useMemo } from 'react';
import { useUiElementState } from '../../../context/ui.context';
import { useAppInit } from '../../../context/init-data.context';
import { usePageData } from '../../../context/page-data.context';
import loadable from '@loadable/component';

const DesktopFooter = loadable(
  () => import('DesignComponents/Organisms/Footer/DesktopFooter/DesktopFooter')
);
const MobileFooter = loadable(
  () => import('DesignComponents/Organisms/Footer/MobileFooter/MobileFooter')
);

const noFooter = new Set<string>();

function Footer() {

  const isDesktop = useMedia(mediaQueryTypes.bpMin961);

  const { footer } = useAppInit();
  const { inEditMode, pageType } = usePageData<PageModelBase>();

  const { hideFooter } = useUiElementState();
  const showFooter = useMemo(() => !hideFooter && !!pageType && !noFooter.has(pageType), [hideFooter, pageType]);
  if (!showFooter) return <></>;

  return (
    <FooterElm>
      <LowerFooterContainer>
        {isDesktop || inEditMode ? (
          <DesktopFooter content={footer} />
        ) : (
          <MobileFooter content={footer} />
        )}
      </LowerFooterContainer>
    </FooterElm>
  );
}

const FooterElm = styled('footer', {
  color: '$onSurface',
  backgroundColor: '$surfaceVariant'
});

const LowerFooterContainer = styled('div', {
  py: '$s300',
  '@bpMin721': {
    pt: '$s300',
    pb: '$s300'
  },
  '@bpMin1025': {
    pt: '$s500',
    pb: '$s300'
  }
});

export default Footer;
