import Heading from 'DesignSystem/Typography/Headings/Heading';
import BodyText from 'DesignSystem/Typography/BodyText/BodyText';
import { styled } from 'Theme/stitches.config';
import { Close } from 'DesignSystem/Icons';

import { useTranslations } from '../../../../context/init-data.context';

type ReporterModalProps = {
  onClose: () => void;
};

const ReporterModal = ({ onClose }: ReporterModalProps) => {
  const { errorLabels, commonLabels } = useTranslations();

  return (
    <>
      <Root>
        <ErrorHeader>
          <Heading tag="h2" size="l">
            {errorLabels && errorLabels.errorHeader}
          </Heading>
          <button onClick={onClose}>
            <Close size="m" color={'primary'} title={commonLabels.close} />
          </button>
        </ErrorHeader>

        <BodyText>{errorLabels && errorLabels.errorRequest}</BodyText>
      </Root>
    </>
  );
};

export default ReporterModal;

const Root = styled('div', {
  w: '100%',
  backgroundColor: '$white',
  p: 10,
  h: '100%',
  position: 'fixed',
  zIndex: '$errorModal',
});

const ErrorHeader = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
});
