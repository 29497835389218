import { isArray, isObject, isString, parseError } from '@juulsgaard/ts-tools';
import PageModelBase from '../../Models/Pages/Base/PageModelBase.interface';

export function parseHttpClientError(e: unknown) {
  if (e instanceof HttpClientError) return e;
  const error = parseError(e);
  return new HttpClientError(error.message, error);
}

export class HttpClientError extends Error {
  readonly cancelled: boolean;
  readonly statusCode: number | undefined;

  constructor(
    message: string,
    public readonly inner?: Error
  ) {
    super(message, inner);
    this.cancelled = !!inner && inner.message === 'Request was manually cancelled';
  }

  getValidationErrors(): ValidationErrorModel|undefined {
    return undefined;
  }

  getPageModel(): PageModelBase|undefined {
    return undefined;
  }
}

export class RequestError extends HttpClientError {

  constructor(
    message: string,
    public override readonly statusCode: number,
    public readonly correlationId: string | undefined,
    public readonly data: unknown|undefined,
    inner?: Error
  ) {
    super(message, inner);
  }

  override getValidationErrors(): ValidationErrorModel|undefined {
    const data = this.data;
    if (!isObject(data)) return undefined;
    if (!('error' in data)) return undefined;
    if (!isString(data.error)) return undefined;
    if (!('errors' in data)) return undefined;
    if (!isArray(data.errors)) return undefined;
    return {error: data.error, errors: data.errors.filter(isErrorItem) };
  }

  override getPageModel(): PageModelBase | undefined {
    const data = this.data;
    if (!isObject(data)) return undefined;
    if (!('page' in data)) return undefined;
    if (!isObject(data.page)) return undefined;
    return data.page as PageModelBase;
  }
}

function isErrorItem(item: unknown): item is ValidationErrorItem {
  if (!isObject(item)) return false;
  if (!('property' in item)) return false;
  if (!isString(item.property)) return false;
  if (!('error' in item)) return false;
  if (!isString(item.error)) return false;
  return true;
}

export function getValidationError(error: unknown) {
  if (error instanceof RequestError) return error.getValidationErrors();
  return undefined;
}

export interface ValidationErrorModel {
  error: string;
  errors: ValidationErrorItem[];
}

export interface ValidationErrorItem {
  property: string;
  error: string;
}