import React from 'react';
import type * as Stitches from '@stitches/react';
import { CSS } from '@stitches/react';
import redirect from './Redirect';
import { styled } from 'Theme/stitches.config';
import { focusStateStyle } from 'Shared/Common/focusState';
import { useKexNavigate } from '../../../lib/router/UseKexNavigate';

export type TargetTypes = '_blank' | '_self' | '_top' | '_parent';

type StyledButtonVariants = Stitches.VariantProps<typeof AnchorElement>;

type PropType = {
  onClick?: (x?: () => void) => void;
  onNavigated?: () => void;
  children?: React.ReactNode;
  href?: string;
  target?: TargetTypes;
  title?: string;
  clearQueryString?: boolean;
  Element?: typeof AnchorElement;
  tabIndex?: number;
  ariaAttributes?: Record<string, string | undefined>; // New property for ARIA attributes
  type?: StyledButtonVariants['type'];
  css?: CSS;
};

const InteractableContentLink = ({
  onClick,
  onNavigated,
  children,
  href,
  target = '_self',
  clearQueryString,
  Element,
  tabIndex,
  title,
  ariaAttributes,
  type,
  css,
}: PropType) => {
  const kexNavigate = useKexNavigate();
  const LinkElement = GetAnchor(Element);

  const onClickHandler = (event: any) => {
    if (event.ctrlKey) return;

    event.persist();
    event.preventDefault();
    const { target } = event.currentTarget;
    onClick && onClick();
    redirect({
      kexNavigate,
      target,
      url: href ?? '',
      onNavigated,
      clearQueryString,
    });
  };

  return typeof href === 'string' &&
    (href.substring(0, 4) === 'http') ? (
    <LinkElement
      target={target}
      href={href}
      tabIndex={tabIndex}
      title={title}
      type={type}
      css={css}
    >
      {children}
    </LinkElement>
  ) : (
    <LinkElement
      target={target}
      tabIndex={tabIndex}
      css={css}
      onClick={(event) => onClickHandler(event)}
      href={href}
      {...ariaAttributes}
      title={title}
      type={type}
    >
      {children}
    </LinkElement>
  );
};

const AnchorElement = styled('a', {
  ...focusStateStyle,
  variants: {
    type: {
      hero: {
        display: 'flex',
        backgroundColor: '$primaryCtaBackgroundInverse',
        py: '$s90',
        px: '$s150',
        color: '$onInverse',
        minWidth: 'fit-content',
        borderRadius: '$radius$rExtraSmall',
        fontSize: '$fontSizes$fontSize100',
        fontFamily: 'fontSemiBold',
        textTransform: 'uppercase',
      },
    },
  },
});

const GetAnchor = (Element?: typeof AnchorElement) =>
  Element ? Element : AnchorElement;

export default InteractableContentLink;
