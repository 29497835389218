import { createTheme, ThemeProvider } from '@mui/material';
import { ReactNode } from 'react';

const matTheme = createTheme({
  typography: { fontFamily: "Jakarta, Arial, sans-serif" },
  palette: {
    primary: {
      main: '#4D453D'
    }
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          width: "100%",
          padding: "14px 16px",
          fontSize: 16,
          lineHeight: 1.25
        }
      }
    }
  }
});

export default function MatThemeProvider({children}: {children: ReactNode}) {
  return (
    <ThemeProvider theme={matTheme}>
      {children}
    </ThemeProvider>
  )
}