import React, { createContext, ReactNode, useEffect, useMemo, useState } from 'react';
import { isSSR } from '../lib/util/is-ssr';
import { HttpClient } from '../lib/requests/http-client';
import { useHttpClient } from '../lib/requests/HttpClientProvider';
import UserSessionModel from '../Models/App/UserSessionModel.interface';

const UserSessionContext = createContext<UserSessionModel>({} as UserSessionModel);

function loadAppInit(client: HttpClient) {
  return client.get('api://init/session').go<UserSessionModel>();
}

interface Props {
  children: ReactNode;
  initData: UserSessionModel | undefined;
}

export function SessionLoader({ children, initData }: Props) {
  const [data, setData] = useState(initData);
  const hasData = useMemo(() => !!data, [data]);

  const client = useHttpClient();
  useEffect(() => {
    if (isSSR()) return;

    if (hasData) {
      document.getElementById('site-loader')?.remove();
      return;
    }

    const req = loadAppInit(client);
    req.then(setData);

    // Cancel request in strict mode
    return () => req.cancel();
  }, [hasData]);

  if (!data) return <></>;

  return (
    <UserSessionContext.Provider value={data}>
      {children}
    </UserSessionContext.Provider>
  );
}

export const useUserSession = () => {
  return React.useContext(UserSessionContext);
};