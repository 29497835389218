
export class RouteSnapshot {

  private _completed = false;
  get completed() {return this._completed;}

  get url() {
    const url = `/${this.route.join('/')}`;
    if (!this.query.size) return url;

    const queryParams = new URLSearchParams();
    this.query.forEach((val, key) => queryParams.set(key, val));
    return `${url}?${queryParams.toString()}`;
  }

  constructor(
    readonly route: string[],
    readonly query: Map<string, string>,
    readonly fragment: string | undefined,
    readonly scrollOffset?: number,
    private readonly resolve?: (success: boolean) => void
  ) { }

  // eslint-disable-next-line unused-imports/no-unused-vars
  failed(_error: Error): void {
    this._completed = true;
    this.resolve?.(false);
  }

  cancelled(): void {
    this._completed = true;
    this.resolve?.(false);
  }

  succeeded(): void {
    this._completed = true;
    this.resolve?.(true);
  }

  skipped() {
    this._completed = true;
    this.resolve?.(true);
  }

  // TODO: Should we invalidate the page load on query param changes?
  equals(routeSnapshot: RouteSnapshot) {
    if (routeSnapshot.route.length !== this.route.length) return false;
    return this.route.every((val, i) => routeSnapshot.route[i] === val);
  }
}