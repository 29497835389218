import { Routes } from './route-store';
import loadable from '@loadable/component';

export const blocks = {
  ProductListingBlock: loadable(() => import(
    'Cms/Blocks/ProductListingBlock/ProductListingBlock'
    )),
  SubscriptionOffersBlock: loadable(() => import(
    'Cms/Blocks/SubscriptionOffersBlock/SubscriptionOffersBlock'
    )),
  TextBlock: loadable(() => import(
    'Cms/Blocks/TextBlock'
    )),
  CTAContainer: loadable(() => import(
    'Cms/Blocks/CTAContainerBlock/CTAContainerBlock'
    )),
  ContentBlock: loadable(() => import(
    'Cms/Blocks/ContentBlock/ContentBlock'
    )),
  MagazineListingBlock: loadable(() => import(
    'Cms/Blocks/MagazineListingBlock'
    )),
  BrandListingBlock: loadable(() => import(
    'Cms/Blocks/BrandListingBlock/BrandListningBlock'
    )),
  GiftCardBlock: loadable(() => import(
    'Cms/Blocks/GiftCardBlock/GiftCardBlock'
    )),
  CookieBotDeclarationBlock: loadable(() => import(
    'Cms/Blocks/CookieBotDeclarationBlock'
    )),
  ContactUsBlock: loadable(() => import(
    'Cms/Blocks/ContactUsBlock/ContactUsBlock'
    )),
  BookClubListingBlock: loadable(() => import(
    'Cms/Blocks/BookClubListingBlock/BookClubListingBlock'
    )),
  VoucherPdfCreateBlock: loadable(() => import(
    'Cms/Blocks/VoucherPdfCreateBlock/VoucherPdfCreateBlock'
    ))
} as const satisfies Routes;