import loadable from '@loadable/component';
import { Routes } from './route-store';

export const routes: Routes = {
  StartPage: loadable(() => import(
    /* webpackPrefetch: true */ 'Cms/Pages/StartPage/StartPage'
    )),
  StandardPage: loadable(() => import(
    /* webpackPrefetch: true */ 'Cms/Pages/StandardPage/StandardPage'
    )),
  CampaignCollectionPage: loadable(() => import(
    /* webpackPrefetch: true */ 'Cms/Pages/CampaignCollectionPage/CampaignCollectionPage'
    )),
  ArticleProductPage: loadable(() => import(
    /* webpackPrefetch: true */ 'Commerce/Pages/ProductPage/ArticleProductPage'
    )),
  GiftCardProductPage: loadable(() => import(
    /* webpackPrefetch: true */ 'Commerce/Pages/ProductPage/GiftCardProductPage'
    )),
  SubscriptionOfferProductPage: loadable(() => import(
    /* webpackPrefetch: true */ 'Commerce/Pages/ProductPage/SubscriptionOfferProductPage'
    )),
  MagazineOverviewPage: loadable(() => import(
    /* webpackPrefetch: true */ 'Cms/Pages/MagazineOverviewPage/MagazineOverviewPage'
    )),
  MagazinePage: loadable(() => import(
    /* webpackPrefetch: true */ 'Cms/Pages/MagazinePage/MagazinePage'
    )),
  ArticleCategoryPage: loadable(() => import(
    /* webpackPrefetch: true */ 'Commerce/Pages/CategoryPage/ArticleCategoryPage'
    )),
  BrandPage: loadable(() => import(
    /* webpackPrefetch: true */ 'Cms/Pages/BrandPage/BrandPage'
    )),
  CheckoutPage: loadable(() => import(
    /* webpackPrefetch: true */ 'Commerce/Pages/Checkout/CheckoutPage'
    )),
  OrderConfirmationPage: loadable(() => import(
    /* webpackPrefetch: true */ 'Commerce/Pages/OrderConfirmation/OrderConfirmationPage'
    )),
  SearchPage: loadable(() => import(
    /* webpackPrefetch: true */ 'Cms/Pages/SearchPage/SearchPage'
    )),
  GiftCardCodePage: loadable(() => import(
    /* webpackPrefetch: true */ 'Commerce/Pages/GiftCardCodePage/GiftCardCodePage'
    )),
  BookClubPage: loadable(() => import(
    /* webpackPrefetch: true */ 'Cms/Pages/BookClubPage/BookClubPage'
    )),
  NotFoundPage: loadable(() => import(
    'Cms/Pages/NotFoundPage/NotFoundPage'
    )),
  ErrorPage: loadable(() => import(
    'Cms/Pages/ErrorPage/ErrorPage'
    ))
};