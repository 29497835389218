import { useState } from 'react';

export class LoadingState {

  private _loading = true;
  get loading() {return this._loading;}

  constructor(private onCancel: (state: LoadingState) => void) {
  }

  cancel() {
    this._loading = false;
    this.onCancel(this);
  }
}

export class LoadingService {
  constructor(private setLoading: (loading: boolean) => void) {
  }

  private _loading = new Set<LoadingState>();
  get loading() {return !!this._loading.size}

  startLoad(): LoadingState {
    const state = new LoadingState(x => {
      this._loading.delete(x);
      if (this._loading.size <= 0) this.setLoading(false);
    });
    this._loading.add(state);
    this.setLoading(true);
    return state;
  }

  reset() {
    this._loading.forEach(x => x.cancel());
  }
}

export function useLoadingService(): [loading: boolean, service: LoadingService] {
  const [loading, setLoading] = useState(false);
  const [service] = useState(() => new LoadingService(setLoading));
  return [loading, service];
}