import { hasFileExtension, isEmailLink } from 'Shared/Common/Helpers';
import { KexNavigateType } from '../../../lib/router/UseKexNavigate';

type RedirectType = {
  kexNavigate: KexNavigateType;
  target: string;
  url: string;
  onNavigated?: () => void;
  clearQueryString?: boolean;
};

const redirect = ({
  kexNavigate,
  target,
  url = '',
  onNavigated,
  clearQueryString,
}: RedirectType) => {
  if (url === '' || url === undefined) return;
  if (target !== '_blank' && { url } && !hasFileExtension({ url })) {
    onNavigated
      ? kexNavigate(url, undefined, clearQueryString).then(() => onNavigated())
      : kexNavigate(url, undefined, clearQueryString);
  } else if (isEmailLink({ url })) {
    window.location.href = url;
  } else {
    window.open(url, '_blank');
  }
};

export default redirect;
