/*
 * Used to check if we are in server context or not.
 */

import { useEffect, useState } from 'react';

export const canUseDOM = () => {
  return !!(
    typeof window !== 'undefined' &&
    window.document &&
    window.document.createElement
  );
};

type DimensionType = {
  innerWidth: number;
  innerHeight: number;
};

// TODO: Lord have mercy. This should be debounced!
export function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState<DimensionType>({
    innerWidth: 0,
    innerHeight: 0,
  });

  /* eslint consistent-return: off */
  useEffect(() => {
    canUseDOM() &&
      setWindowDimensions({
        innerWidth: window.innerWidth,
        innerHeight: window.innerHeight,
      });
    function handleResize() {
      if (canUseDOM())
        setWindowDimensions({
          innerWidth: window.innerWidth,
          innerHeight: window.innerHeight,
        });
    }
    if (canUseDOM()) window.addEventListener('resize', handleResize);

    if (canUseDOM())
      return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}
