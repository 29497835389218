import { useTranslations } from '../../context/init-data.context';
import { Button } from '@mui/material';
import { useRouter } from '../../lib/router/router-hook';
import styles from './GenericError.module.scss';
import { ReactNode } from 'react';

interface Props {
  heading?: string;
  bodyText?: string;
  errorMessage?: string;
  statusCode?: number;
  children?: ReactNode;
}

export function GenericError({ heading, bodyText, errorMessage, children }: Props) {
  const { errorLabels: { errorHeader } } = useTranslations();
  const { commonLabels: { goHome } } = useTranslations();
  const router = useRouter();
  return (
    <div className={styles.errorPage}>
      <div className={styles.banner}>
        <h1>{heading ?? errorHeader}</h1>
        {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
        {bodyText && <p>{bodyText}</p>}
        <Button className={styles.button} style={{ width: 'auto' }} variant="contained"
                onClick={() => router.goHome()}>{goHome}</Button>
      </div>
      {children}
    </div>
  );
}