import ContentAreaItem from 'Models/App/ContentAreaItem.interface';
import React from 'react';
import { BlockRenderer } from '../../../routing/BlockRenderer';

type Props = {
  items: ContentAreaItem[];
};

function ContentArea({ items }: Props) {
  return (
    <>
      {items.map((item, i) => <BlockRenderer key={i} blockType={item.blockType} content={item.content}/>)}
    </>
  );
}

export default ContentArea;
