import PageModelBase from 'Models/Pages/Base/PageModelBase.interface';
import { Helmet } from 'react-helmet-async';

import { usePageData } from '../../context/page-data.context';

function PageMetaData() {
  const {
    alternativeLinks,
    canonicalLink,
    disableIndexing,
    metaDescription,
    openGraphDescription,
    openGraphImageUrl,
    openGraphTitle,
    openGraphType,
    htmlTitle,
  } = usePageData<PageModelBase>();

  return (
    <Helmet prioritizeSeoTags>
      <title>{htmlTitle}</title>
      {Object.keys(alternativeLinks).map((lang, idx) => (
        <link
          key={idx}
          rel="alternate"
          href={alternativeLinks[lang]}
          hrefLang={lang}
          data-rh="true"
        />
      ))}
      {canonicalLink && (
        <link rel="canonical" href={canonicalLink} data-rh="true" />
      )}
      {metaDescription && (
        <meta name="description" content={metaDescription} data-rh="true" />
      )}
      {openGraphTitle && (
        <meta property="og:title" content={openGraphTitle} data-rh="true" />
      )}
      {openGraphType && (
        <meta property="og:type" content={openGraphType} data-rh="true" />
      )}
      {canonicalLink && (
        <meta property="og:url" content={canonicalLink} data-rh="true" />
      )}
      {openGraphDescription && (
        <meta
          property="og:description"
          content={openGraphDescription}
          data-rh="true"
        />
      )}
      {openGraphImageUrl && (
        <meta property="og:image" content={openGraphImageUrl} data-rh="true" />
      )}
      {disableIndexing && (
        <meta name="robots" content="noindex, nofollow" data-rh="true" />
      )}
    </Helmet>
  );
}

export const SSRMetaData = (page: PageModelBase | undefined) => {
  if (!page) return '';

  const alternateLinks = page.alternativeLinks
    ? Object.keys(page.alternativeLinks).reduce(
        (previousValue, key: string) => {
          return `${previousValue} <link rel="alternate" href="${page.alternativeLinks[key]}" hreflang="${key}" /> \n`;
        },
        ''
      )
    : '';

  const pageTitle = page.htmlTitle
    ? `<title>${page.htmlTitle}</title data-rh="true">`
    : '';
  const canonicalLink = page.canonicalLink
    ? `<link rel="canonical" href="${page.canonicalLink}" data-rh="true">`
    : '';
  const description = page.metaDescription
    ? `<meta name="description" content="${page.metaDescription}" data-rh="true">`
    : '';
  const ogTitle = page.openGraphTitle
    ? `<meta property="og:title" content="${page.openGraphTitle}" data-rh="true" data-rh="true">`
    : '';
  const ogType = page.openGraphType
    ? `<meta property="og:title" content="${page.openGraphType}" data-rh="true" data-rh="true">`
    : '';
  const ogDescription = page.openGraphDescription
    ? `<meta property="og:description" content="${page.openGraphDescription}" data-rh="true">`
    : '';
  const ogUrl = page.openGraphUrl
    ? `<meta property="og:url" content="${page.openGraphUrl}" data-rh="true">`
    : '';
  const ogImage = page.openGraphImageUrl
    ? `<meta property="og:image" content="${page.openGraphImageUrl}" data-rh="true">`
    : '';
  const disableIndex = page.disableIndexing
    ? `<meta name="robots" content="noindex, nofollow" data-rh="true">`
    : '';
  return `${disableIndex}${pageTitle}
  ${alternateLinks}
  ${canonicalLink}
  ${description}
  ${ogTitle}
  ${ogDescription}
  ${ogUrl}
  ${ogType}
  ${ogImage}`;
};

export default PageMetaData;
