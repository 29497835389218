import React, { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import PageModelBase from '../Models/Pages/Base/PageModelBase.interface';
import { useRouteSnapshot } from '../lib/router/router-hook';
import { HttpClient } from '../lib/requests/http-client';
import { isSSR } from '../lib/util/is-ssr';
import { usePageSpinner } from '../components/PageSpinner';
import { useSnacks } from '../lib/snackbars/snackbars';
import { useHttpClient } from '../lib/requests/HttpClientProvider';
import { RouteSnapshot } from '../lib/router/route-snapshot';
import { gtmHandlePageEvent } from '../Shared/GTM/GtmPageEventHandler';
import { HttpClientError } from '../lib/requests/request-error';
import { GenericError } from 'components/GenericError/GenericError';

function loadPage(client: HttpClient, route: RouteSnapshot) {
  return client.get(route.url)
    .withHeader('X-PageLoad', 'true')
    .go<PageModelBase>();
}

const PageDataContext = createContext<PageModelBase>({} as PageModelBase);
const PageRouteContext = createContext<PageRoute>({} as PageRoute);

interface PageRoute {
  page: PageModelBase;
  route: RouteSnapshot;
}

interface Props {
  children: ReactNode;
  initPage: PageModelBase | undefined;
}

interface LoadError {
  route: RouteSnapshot;
  error: HttpClientError;
}

export function PageLoader({ children, initPage }: Props) {

  const route = useRouteSnapshot();
  const [data, setData] = useState<PageRoute | undefined>(() => {
    if (!initPage) return undefined;
    return { route, page: initPage };
  });
  const pageSpinner = usePageSpinner();
  const [error, setError] = useState<LoadError | undefined>();

  useEffect(() => {
    if (data) route.skipped();
  }, []);

  const client = useHttpClient();
  const snacks = useSnacks();

  useEffect(() => {
    if (isSSR()) return;
    if (route.completed) {
      setData(data => data ? { page: data.page, route } : undefined);
      return;
    }

    const loading = pageSpinner.startLoad();
    const req = loadPage(client, route);
    req.finally(() => loading.cancel());

    req.then(
      page => setData({ route, page }),
      e => {
        if (e.cancelled) return;
        snacks.showError(e.message);

        const page = e.getPageModel();
        if (page) setData({ route, page });
        else setError({route, error: e});
      }
    );

    req.then(
      () => route.succeeded(),
      e => route.failed(e)
    );

    // Cancel request in strict mode
    return () => req.cancel();
  }, [route]);


  // Trigger GTM events
  // TODO: Should this trigger on route updates for page views, or just for data loads?
  useEffect(() => {
    if (!data?.page) return;
    gtmHandlePageEvent(data.page);
  }, [data?.page]);

  if (error && error.route === route) {
    return <GenericError errorMessage={error.error.message} statusCode={error.error.statusCode} />;
  }

  if (!data) return <></>;

  return (
    <PageDataContext.Provider value={data.page}>
      <PageRouteContext.Provider value={data}>
        {children}
      </PageRouteContext.Provider>
    </PageDataContext.Provider>
  );
}

export function usePageData<T extends PageModelBase = PageModelBase>(): T {
  return useContext(PageDataContext) as T;
}

export function usePageRoute(): PageRoute {
  return useContext(PageRouteContext);
}